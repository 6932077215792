import { Skeleton } from '@components/ui/skeleton'
import { FC } from 'react'

interface HeroHeaderProps {
    heading?: string
    subHeading?: string
    center?: boolean
    className?: string
}

const HeroHeadersSkeleton: FC<HeroHeaderProps> = ({ heading = 'default default', className = "", center = false}) => {
    return (
        <div className={`flex flex-col ${center ? "justify-center md:items-center" : ""} ${className}`}>
            <Skeleton className='mobile_large md:headline_large text-transparent px-2 max-w-fit'>{heading}</Skeleton>
        </div>
    )
}

export default HeroHeadersSkeleton