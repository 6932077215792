'use client';
import { FC, useEffect, useState } from 'react';

interface ZobotWidget {
  widgetCode: string;
}

const Zobot: FC<ZobotWidget> = ({ widgetCode }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadZobot = () => {
      if (isLoaded) return;

      const scriptId = 'zsiqchat';

      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = scriptId;
        script.innerHTML = `
          var $zoho=$zoho || {};
          $zoho.salesiq = $zoho.salesiq || {
            widgetcode: '${widgetCode}',
            values: {},
            ready: function(){}
          };
          var d=document;
          s=d.createElement("script");
          s.type="text/javascript";
          s.id="zsiqscript";
          s.defer=true;
          s.src="https://salesiq.zohopublic.in/widget";
          var t=d.getElementsByTagName("script")[0];
          t.parentNode.insertBefore(s,t);
        `;

        document.body.appendChild(script);
        setIsLoaded(true);
      }
    };

    // Lazy-load on interaction (scroll or click)
    const onUserInteraction = () => {
      loadZobot();
      window.removeEventListener('scroll', onUserInteraction);
      window.removeEventListener('click', onUserInteraction);
    };

    window.addEventListener('scroll', onUserInteraction);
    window.addEventListener('click', onUserInteraction);

    return () => {
      window.removeEventListener('scroll', onUserInteraction);
      window.removeEventListener('click', onUserInteraction);
    };
  }, [isLoaded, widgetCode]);

  return null;
};

export default Zobot;
