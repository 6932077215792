'use client';
import leftArrow from '@assets/leftArrowCarousel.svg';
import Image from 'next/image';
import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import VideoPlayer from './VideoPlayer';

interface CarouselForVideosProps extends React.HTMLAttributes<HTMLElement> {
  reviews: string[]
}

const CarouselForVideos: FC<CarouselForVideosProps> = ({ reviews }) => {
  const sliderSettings = {
    centerMode: true,
    centerSlidePercentage: 33,
    showArrows: true,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: true,
    stopOnHover: true,
    swipeable: true,
    emulateTouch: true,
    width: '90vw',
    // renderItem: (children: ReactNode) => {
    //   return <div className="mb-12 mx-2">{children}</div>
    // },
    renderArrowPrev: (clickHandler: any, hasPrev: any) => {
      return (
        <div
          className={`${hasPrev ? 'absolute' : 'hidden'
            } top-[40%] -left-1 opacity-30 hover:opacity-100 cursor-pointer z-20`}
          onClick={clickHandler}
        >
          <Image src={leftArrow} alt="click to go to previous image" />
        </div>
      )
    },
    renderArrowNext: (clickHandler: any, hasNext: any) => {
      return (
        <div
          className={`${hasNext ? 'absolute' : 'hidden'
            } bottom-[40%] right-0 opacity-30 hover:opacity-100 cursor-pointer z-50`}
          onClick={clickHandler}
        >
          <Image src={leftArrow} className=' scale-[-1]' alt="click to go to next image" />
        </div>
      )
    },
    renderIndicator: (onClickHandler: any, isSelected: boolean, index: number, label: string) => {
      const defStyle = {
        marginLeft: 10,
        width: 10,
        height: 10,
        borderRadius: '5px',
        backgroundColor: 'white',
        cursor: 'pointer',
        display: 'inline-block',
        transition: 'width 0.6s ease, background-color 0.6s ease'
      };


      const style = isSelected
        ? { ...defStyle, width: 20, backgroundColor: 'rgb(232, 0, 113)' }
        : { ...defStyle };

      return (
        <div
          style={style}
          onClick={onClickHandler}
          key={index}
          role="button"
          tabIndex={0}
          aria-hidden="true"
          aria-label={`${label} ${index + 1}`}
          title={`video ${label} ${index + 1}`}
        />
      );
    }
  }

  return (
    <Carousel {...sliderSettings} showThumbs={false}>
      {reviews.map((review, index) => (
        <VideoPlayer key={`${index}_videoPlayer`} src={review} />
      ))}
    </Carousel>
  )
}

export default CarouselForVideos
