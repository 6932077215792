"use client";
import React from 'react';
import HeroHeadersSkeleton from './HeroHeadersSkeleton';
import { Skeleton } from '@components/ui/skeleton';
import AppDivider from '../AppDivider';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@components/ui/tabs';
import ProductCardSkeleton from '../ProductCardSkeleton';
import { useMediaQuery } from '@mantine/hooks';

const ProdcutOverviewSkeleton = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  // Mock categories for demonstration
  const categories = Array.from({ length: 8 }, (_, i) => ({
    categoryid: `category_${i + 1}`,
    name: `Category ${i + 1}`
  }));

  return (
    <section data-aos="fade-up">
      <div className="flex flex-col">
        <HeroHeadersSkeleton />
        <AppDivider short />
        <div className={`overflow-x-hidden`}>
          <Tabs defaultValue={categories[0].categoryid}>
            <div className={`overflow-x-auto scroll-hint-animation`}>
              <TabsList className="gap-4 title_large">
                {categories.map((category, index) => (
                  <Skeleton key={`array_empty_${index}`}>
                    <TabsTrigger value={category.categoryid}>
                      <Skeleton className=" text-transparent">{category.name}</Skeleton>
                    </TabsTrigger>
                  </Skeleton>
                ))}
              </TabsList>
            </div>
            <hr className="hidden md:flex" />
            {categories.map((category, index) => (
              <TabsContent defaultValue={categories[0].categoryid} value={category.categoryid} key={`tabsContent_${index}`}>
                <div className="grid grid-cols-2 p-2 md:grid-cols-4 md:grid-rows-2 gap-3">
                  {Array.from({ length: isMobile ? 4 : 8 }, (_, i) => (
                    <ProductCardSkeleton key={`random_card_${i}`} />
                  ))}
                </div>
              </TabsContent>
            ))}
          </Tabs>
        </div>
      </div>
      <AppDivider short />
      <div className="w-full flex justify-center px-2 md:mt-4">
        <Skeleton className="text-transparent w-full md:w-auto flex justify-center bg-surface-50 title_medium md:px-7 py-4">
          View All Products
        </Skeleton>
      </div>
      <AppDivider grande />
    </section>
  );
};

export default ProdcutOverviewSkeleton;
