'use client'
import { userContext } from '@components/context/userContext'
import { Skeleton } from '@components/ui/skeleton'
import GetImageUrl from '@lib/GetImageUrl'
import { Product } from '@lib/GetProducts'
import { useMediaQuery } from '@mantine/hooks'

import { sendGAEvent, sendGTMEvent } from '@next/third-parties/google'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React, { FC, useContext, useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

export interface ProductCardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  product: Product
  category: string
  occasion: string
  isLoading?: boolean
}

const LazyRatingPreviewCard = React.lazy(() => import('./RatingPreviewCardUnderProductCard'));


const ProductCard: FC<ProductCardProps> = ({ product, isLoading = false, category, occasion }) => {
  const path = usePathname()
  const isMobile = useMediaQuery('(max-width: 768px)')
  const [imageIdArray, setImageIdArray] = useState<number[]>([])
  const [hovered, setHovered] = useState(false)
  const [isInWishlist, setIsInWishlist] = useState(false)
  const [isMovingToCart, setIsMovingToCart] = useState(false)
  const { wishlist } = useContext(userContext)
  const isInWishList = path === '/wishlist'
  const [imageLoaded, setImageLoaded] = useState(true)

  useEffect(() => {
    if (wishlist) setIsInWishlist(wishlist.some((wish) => wish.productId === product.productId))
  }, [product.productId, wishlist])

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    stopOnHover: true,
    showStatus: false,
    showThumbs: false,
    showArrows: !isMobile,
    showIndicators: imageIdArray.length !== 1 && !isMobile,
    renderIndicator: (onClickHandler: any, isSelected: boolean, index: number, label: string) => {
      const defStyle = {
        marginLeft: 10,
        width: 10,
        height: 10,
        borderRadius: '5px',
        backgroundColor: 'white',
        cursor: 'pointer',
        display: 'inline-block',
        transition: 'width 0.6s ease, background-color 0.6s ease'
      }

      const style = isSelected
        ? { ...defStyle, width: 20, backgroundColor: 'rgb(232, 0, 113)' }
        : { ...defStyle }

      const maxIndicators = 3
      if (index >= maxIndicators && isMobile) {
        return null
      }

      return (
        <div
          style={style}
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          key={index}
          role="button"
          tabIndex={0}
          aria-label={`${label} ${index + 1}`}
          title={`${label} ${index + 1}`}
        />
      )
    }
  }

  useEffect(() => {
    if (product.imageIds) {
      if (isMobile) {
        setImageIdArray([product.imageIds[0]]);
      } else {
        setImageIdArray(product.imageIds);
      }
    }
  }, [product, isMobile]);


  return (
    <div className="flex flex-col relative cursor-pointer flex-1 h-full">
      <div className=" flex flex-col justify-center items-start border md:border-none rounded-md md:rounded-none p-0">
        <div className=" w-full aspect-square relative">
          {product.ribbon ? (
            <div
              className=" absolute top-4 md:top-3 z-10  text-white rounded-r-sm md:rounded-r-md mobile_thin_ribbon md:label_normal"
              style={{ backgroundColor: product.ribbonColor ? product.ribbonColor : '#4C48F8' }}
            >
              <div className=" mx-3">{product.ribbon}</div>
            </div>
          ) : null}
          {!isLoading && imageIdArray ? (
            <Carousel {...sliderSettings}>
              {imageIdArray.map((image, index) => (
                <div className=" h-full relative aspect-square overflow-hidden" key={index}>
                  <Link
                    className=" h-full flex flex-col justify-between"
                    href={{
                      pathname: `/product/${product.productId}`,
                      query: { category: category, occasion: occasion, prod: product.productName }
                    }}
                    onClick={() => {
                      sendGAEvent('event', 'PRODUCT_CARD_CLK', {
                        productId: product.productId,
                        category: category,
                        occasion: occasion,
                        product: product.productName
                      })
                      sendGTMEvent({
                        event: 'PRODUCT_CARD_CLK',
                        value: {
                          productId: product.productId,
                          category: category,
                          occasion: occasion,
                          product: product.productName
                        }
                      })
                    }}
                  >
                    <img
                      src={GetImageUrl(image.toString())}
                      alt={`image ${index}`}
                      className="rounded-t-sm md:rounded-sm hover:scale-105"
                      loading='lazy'
                      style={{ objectFit: 'cover', objectPosition: 'center' }}
                      onLoad={() => {
                        setImageLoaded(true)}}
                      width={100}
                      height={100}
                    />
                    {imageLoaded ? null : (
                      <span
                        style={{
                          display: imageLoaded ? 'hidden' : 'visible',
                          position: 'absolute',
                          top: 0
                        }}
                        className=" w-full h-full flex justify-center items-center"
                      >
                        <Skeleton className=" w-full h-full rounded-t-md" />
                      </span>
                    )}
                  </Link>
                </div>
              ))}
            </Carousel>
          ) : (
            <Skeleton className=" w-full aspect-square md:h-[300px]  px-2" />
          )}
          <div className=" absolute bottom-1 md:bottom-8">
            <LazyRatingPreviewCard
              productId={product.productId}
            />
          </div>
        </div>
        <Link
          className=" h-full flex flex-col justify-between p-2 md:p-0"
          href={{
            pathname: `/product/${product.productId}`,
            query: { category: category, occasion: occasion, prod: product.productName }
          }}
        >
          <div className="flex flex-col md:gap-1 md:mb-2">
            <div className=" mobile_thin_subnormal md:heading_small line-clamp-1 md:mt-2 text-neutral-800 text-base text-ellipsis overflow-hidden">
              {product.productName}
            </div>
          </div>

          <div className="flex items-center justify-start gap-3 md:ml-2">
            <div className="heading_small hidden md:flex text-neutral-850">
              {product.actualPrice ? (
                <div>₹{product.calculatedBasePrice}</div>
              ) : (
                <div>₹ NotFound</div>
              )}
            </div>
            <div className=" line-through text-neutral-500 mobile_thin_small md:label_medium">
              ₹{product.actualPrice}
            </div>
            <div className=" mobile_condensed_small md:label_medium text-primary-500">
              {product.discountedPrice}% OFF
            </div>
          </div>
          <div className="heading_small md:pl-2 flex md:hidden text-neutral-850">
            {product.actualPrice ? (
              <div>₹{product.calculatedBasePrice}</div>
            ) : (
              <div>₹ NotFound</div>
            )}
          </div>
        </Link>
        {/* {isInWishList ? (
          <Link
            className=" w-full"
            href={{
              pathname: `/product/${product.productId}`,
              query: { category: category, occasion: occasion, prod: product.productName }
            }}
            onClick={() => sendGAEvent( 'event', 'PRODUCT_CARD_CLK', { productId: product.productId, category: category, occasion: occasion, product: product.productName })}
          >
            <Button
              variant="outline"
              className=" hover:bg-primary-500 hover:text-white md:font-bold border-primary-500 text-primary-500 w-full my-2 whitespace-nowrap"
              disabled={isMovingToCart}
            >
              {isMovingToCart ? (
                <span className="animate-spin">
                  <Loader2 />
                </span>
              ) : (
                <span className=" flex items-center gap-2">
                  <Search height={20} /> VIEW PRODUCT
                </span>
              )}
            </Button>
          </Link>
        ) : null} */}
      </div>

      {/* <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={`absolute top-3 md:top-2 right-3 md:right-2 p-1 md:p-2 bg-white md:opacity-40 ${
          isInWishlist ? ' md:opacity-100 border-primary-500' : null
        } md:hover:opacity-100 rounded-full z-20`}
      >
        <AddToWishList wishlisted={isInWishlist} product={product} hovered={hovered} />
      </div> */}
    </div>
  )
}

export default ProductCard
