import(/* webpackMode: "eager" */ "/vercel/path0/assets/InstructionSteps/arrowDown.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/InstructionSteps/arrowUp.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/navigateyourevent/AddtoCart.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/navigateyourevent/CelebrateinStyle.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/navigateyourevent/DeliveryandSetup.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/navigateyourevent/Discover.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/navigateyourevent/Placeorder.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/whyChooseUs/bestPriceguarantee.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/whyChooseUs/easyQuickBooking.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/assets/whyChooseUs/reliableQualitySt.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/atoms/CarouselForVideos.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/atoms/skeleton/ProdcutOverviewSkeleton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/atoms/VideoPlayer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/molecules/OccasionwiseTabsWithProducts.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/molecules/Zobot.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/carousel.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/wrappers/LazySection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js")