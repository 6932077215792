'use client';
import React, { useState, useEffect } from 'react';
import { Skeleton } from '@components/ui/skeleton';
import dynamic from 'next/dynamic';

const VideoPlayer: React.FC<{ src: string }> = ({ src }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const videoId = new URL(src).searchParams.get('v');
    if (!videoId) {
        return <p>Invalid YouTube URL</p>;
    }

    useEffect(() => {
        const handleScroll = () => {
            const videoHolder = document.getElementById('react-player-holder');
            if (videoHolder && videoHolder.getBoundingClientRect().top < window.innerHeight) {
                setIsLoaded(true);
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div id="react-player-holder" className="w-full h-full rounded-2xl overflow-hidden min-h-[190px]">
            {isLoaded ? (
                <React.Suspense fallback={<Skeleton className="w-full h-full min-h-[190px] rounded-2xl bg-primary-200" />}>
                    {React.createElement(
                        dynamic(() => import('react-player'), { ssr: false }),
                        {
                            url: `https://www.youtube.com/embed/${videoId}?rel=0`,
                            width: '100%',
                            height: '100%',
                            controls: true,
                            className: 'react-player'
                        }
                    )}
                </React.Suspense>
            ) : (
                <Skeleton className="w-full h-full min-h-[190px] rounded-2xl" />
            )}
        </div>
    );
};

export default VideoPlayer;
