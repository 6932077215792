'use client'
import { Skeleton } from '@components/ui/skeleton';
import { Heart } from 'lucide-react';
import { FC } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader


const ProductCardSkeleton: FC = () => {
  return (
    <div className="flex flex-col relative cursor-default flex-1 h-full justify-between">
      <div className=" flex flex-col justify-center items-center md:p-0  border border-neutral-50 md:border-none rounded-md md:rounded-none ">
        <Skeleton className=" w-full aspect-square px-2 rounded-none md:rounded-md" />
        <div className=" w-full p-2 md:px-0 ">
          <div className="flex flex-col w-full justify-between pb-1">
            <div className="flex flex-col gap-1">
              <Skeleton className=" h-[1.5em] md:h-[1.2em] heading_small px-2 text-base text-ellipsis overflow-hidden hidden md:flex" />
            </div>
          </div>
          <div className=" flex self-start w-[100%] gap-1 md:hidden">
            <Skeleton className=" w-[30%] flex md:hidden h-[1em] heading_small px-2 self-start" />
            <Skeleton className=" w-[30%] flex md:hidden h-[1em] heading_small px-2 self-start" />
          </div>
          <Skeleton className=" mt-1 w-[40%] flex md:hidden h-[1.4em] md:h-[1.2em] heading_small px-2 self-start" />
        </div>
      </div>
      <Skeleton className=" hidden md:flex h-[1.5em] md:h-[1.2em] heading_small px-2 text-base text-ellipsis overflow-hidden" />
      <div className="absolute top-2 right-2 p-3 text-white rounded-full">
        <Heart size={24} strokeWidth={1} />
      </div>
    </div>
  )
}

export default ProductCardSkeleton
