'use client'
import ProductCard from '@components/atoms/ProductCard'
import { convertToProduct } from '@components/context/userContext'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@components/ui/tabs'
import { OccasionProps } from '@hooks/useOccasions'
import { CategoryProps } from '@lib/GetCategories'
import { DbProductProps } from '@lib/GetProducts'
import { useMediaQuery } from '@mantine/hooks'
import { sendGAEvent } from '@next/third-parties/google'
import Link from 'next/link'
import React, { FC, useEffect, useState } from 'react'

export interface OccasionwiseTabsWithProductsProps extends React.HTMLAttributes<HTMLElement> {
  occasions: OccasionProps[]
  productList: DbProductProps[]
  occasion: OccasionProps
  category?: string | undefined | string[]
  categories: CategoryProps[]
  searchParams?: { [key: string]: string | string[] | undefined }
}

const OccasionwiseTabsWithProducts: FC<OccasionwiseTabsWithProductsProps> = ({ productList = [], occasions, searchParams, categories, occasion }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [showAnimation, setShowAnimation] = useState(true);
  const products = productList.map((product: DbProductProps) => convertToProduct(product))
  const category = searchParams ? searchParams[occasion.occasionid]?.toString() : categories[0].categoryid;

  const onTabChange = (tab: CategoryProps) => {
    sendGAEvent({ event: 'OCCASION_ESSENTIALS_CLK', occasion: tab.categoryid })
  }

  useEffect(() => {
    if (!showAnimation) return
    // Trigger the scroll hint animation for a limited time
    const timer = setTimeout(() => {
      setShowAnimation(false);
    }, 750); // Run the animation for 1.5 seconds

    return () => clearTimeout(timer);
  }, [showAnimation]);

  return (
    <>
      <div className={`overflow-x-hidden`}>
        {/* <div className=" overflow-x-hidden flex-nowrap"> */}
        <Tabs defaultValue={category ?? categories[0].categoryid}>
          <div className={`overflow-x-auto  ${showAnimation ? 'scroll-hint-animation' : ''} scrollable-x`}>
            <TabsList className=" gap-4 title_large">
              {categories.map((category, index) => (
                <Link
                  scroll={false}
                  key={`tabsTrigger_${index}`}
                  href={{
                    query: {
                      ...searchParams,
                      [occasion.occasionid]: category.categoryid
                      // category: category.categoryid
                    }
                  }}> <TabsTrigger
                    value={category.categoryid}
                    onClick={() => onTabChange(category)}
                    defaultValue={categories[0].categoryid}
                  >
                    {category.categoryname}
                  </TabsTrigger></Link>
              ))}
            </TabsList>
          </div>
          <hr className=' hidden md:flex' />
          {categories.map((category, index) => (
            <TabsContent value={category.categoryid} key={`tabsContent_${index}`}>
              <div className="grid grid-cols-2 p-2 md:grid-cols-4 md:grid-rows-2 gap-3">
                {products.slice(0, isMobile ? 4 : 8).map((product, index) => (
                    <ProductCard
                      isLoading={!products}
                      product={product}
                      key={`productCard_${product.productId}_${index}`}
                      category={product.categoryId[0].id}
                      occasion={occasion.occasionid}
                    />
                  ))}
              </div>
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </>
  )
}

export default OccasionwiseTabsWithProducts
